<template>
  <div>
    <div v-if="campaign.campaign_id" class="sec no-bg">
      <span class="ttl main-title">SPC Grouping</span>
      <spc-grouping-container
        id="spc_grouping_cont"
        :advertiser="advertiser"
        :campaign="campaign"
      />
    </div>
    <div class="sec no-bg">
      <span class="ttl main-title">Campaign Info</span>
      <div class="sec campaign-form">
        <div v-if="error" class="error">{{ error }}</div>
        <div class="form-inp">
          <label class="ttl lbl-txt" for="campaign_name">
            Campaign Name <span class="required">*</span>
          </label>
          <input
            id="campaign_name"
            ref="campaign_nameInput"
            v-model="campaign.campaign_name"
            class="camp-name"
            type="text"
            name=""
            placeholder="Your Brand"
            :disabled="campaign.status !== 'draft' || mode === 'VIEW'"
          />
        </div>
        <div class="form-date-inp">
          <label class="ttl lbl-txt" for="start_date">
            Start Date <span class="required">*</span>
          </label>
          <k-date-picker
            :range="false"
            :value-start="campaign.start_date"
            :readonly="campaign.status !== 'draft' || mode === 'VIEW'"
            class="date-box"
            @change="onCampaignStartDateChange"
          />
          <label class="ttl last-lbl lbl-txt" for="end_date">
            End Date <span class="required">*</span>
          </label>
          <k-date-picker
            :range="false"
            :readonly="campaign.status !== 'draft' || mode === 'VIEW'"
            :value-start="campaign.end_date"
            class="date-box"
            @change="onCampaignEndDateChange"
          />
        </div>
        <div class="form-inp control-wrap">
          <label class="ttl lbl-txt" for="industry_name">Industry</label>
          <div v-if="!industriesCntryLangsLoading">
            <b-radio-select-checkbox
              :options="industries"
              :disabled="campaign.status !== 'draft' || mode === 'VIEW'"
              header="Industry"
              class="wide-box"
              @selected-options="onSelectIndustries"
            />
          </div>
          <div v-else>
            <b-loading-spinner class="txt-align-center ttl" />
          </div>
        </div>

        <div class="form-inp">
          <label class="ttl lbl-txt" for="impression_cnt">
            Expected Impressions Count <span class="required">*</span>
          </label>
          <input
            id="impression_cnt"
            ref="impression_cnt"
            v-model="expectedimpressionscount"
            class="camp-name"
            type="Number"
            name=""
            placeholder="Expected Impressions Count"
            :disabled="campaign.status !== 'draft' || mode === 'VIEW'"
          />
        </div>
      </div>
    </div>
    <div v-if="campaign.status === 'draft'" class="sec no-bg">
      <span class="ttl main-title">Brand and competitors</span>
      <div class="sec campaign-form">
        <div class="form-inp">
          <label class="ttl lbl-txt" for="in-target-brand"
            >In-target Brand <span class="required">*</span></label
          >
          <input
            id="global_params_in_campaign_brand"
            v-model="stagedGlobalParams.inTargetBrand"
            class="camp-name"
            type="text"
            name=""
            placeholder="In Target Brand"
            :disabled="campaign.status !== 'draft' || mode === 'VIEW'"
          />
        </div>
        <div class="form-inp">
          <label class="ttl lbl-txt" for="comp-brand-1"
            >Competitive Brand 1 <span class="required">*</span>
          </label>
          <input
            id="global_params_compete_brand1"
            v-model="stagedGlobalParams.competitiveBrand1"
            class="camp-name"
            type="text"
            name=""
            placeholder="Competitive Brand 1"
            :disabled="campaign.status !== 'draft' || mode === 'VIEW'"
          />
        </div>
        <div class="form-inp">
          <label class="ttl lbl-txt" for="comp-brand-2"
            >Competitive Brand 2 <span class="required">*</span></label
          >
          <input
            id="global_params_compete_brand2"
            v-model="stagedGlobalParams.competitiveBrand2"
            class="camp-name"
            type="text"
            name=""
            placeholder="Competitive Brand 2"
            :disabled="campaign.status !== 'draft' || mode === 'VIEW'"
          />
        </div>
        <div class="form-inp">
          <label class="ttl lbl-txt" for="comp-brand-3"
            >Competitive Brand 3<span class="required">*</span></label
          >
          <input
            id="global_params_compete_brand3"
            v-model="stagedGlobalParams.competitiveBrand3"
            class="camp-name"
            type="text"
            name=""
            placeholder="Competitive Brand 3"
            :disabled="campaign.status !== 'draft' || mode === 'VIEW'"
          />
        </div>
        <div class="form-inp">
          <label class="ttl lbl-txt" for="comp-brand-4"
            >Competitive Brand 4 <span class="required">*</span></label
          >
          <input
            id="global_params_compete_brand4"
            v-model="stagedGlobalParams.competitiveBrand4"
            class="camp-name"
            type="text"
            name=""
            placeholder="Competitive Brand 4"
            :disabled="campaign.status !== 'draft' || mode === 'VIEW'"
          />
        </div>
        <div class="form-inp">
          <k-button
            :size="3"
            label="APPLY"
            :disabled="isApplyGlobalParamsBtnDisabled"
            @click="
              applyGlobalParams();
              fetchQuestions();
            "
          />
        </div>
      </div>
    </div>

    <div class="sec no-bg qa-box">
      <span class="ttl main-title">Template Questions Lookup</span>
      <k-nav-tabs
        class="nav-tabs"
        :menu="menuItems"
        :active="activeTab"
        @selected-menu="updateActiveMenu"
      />
      <div class="sec qna" style="margin-top:3.4rem;">
        <b-loading-spinner v-if="industriesCntryLangsLoading" class="txt-align-center ttl" />
        <div v-if="!loadingQuestions && questions.length">
          <div v-if="activeTab === 'SURVEY'">
            <div v-if="errorBrandCompetitor" class="error">{{ errorBrandCompetitor }}</div>
            <div v-for="(question, qi) in questions" :key="qi" class="sec">
              <div class="question">
                <span class="ttl">Question</span
                ><span class="ttl">{{
                  question.custom_question_text || question.question_text
                }}</span>
              </div>
              <div class="question">
                <span class="ttl">Question Name</span>
                <span class="ttl">{{ question.question_name }}</span>
              </div>
              <div v-for="(qp, qpi) in question.questionParams" :key="qpi" class="question">
                <div v-if="qp.param !== 'brand_or_product'" class="custom-ques-blk">
                  <span class="ttl">{{ qp.param }}</span>
                  <span class="ttl">
                    <input
                      :key="qpi"
                      ref="customQuestion"
                      v-model="qp.value"
                      class="ans-select"
                      type="text"
                      :disabled="campaign.status !== 'draft' || mode === 'VIEW'"
                      @input="updateCustomQuestionText(qi)"
                    />
                  </span>
                </div>
              </div>

              <div class="answers">
                <span class="ttl">Answers</span>
                <div class="ttl">
                  <input
                    v-for="(answer, ai) in question.answers"
                    :key="ai"
                    ref="answer"
                    v-model="answer.text"
                    class="ans-select"
                    :disabled="campaign.status !== 'draft' || mode === 'VIEW'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="!loadingQuestions && !questions.length" class="txt-align-center ttl">
          No data is available to show
        </div>
        <b-loading-spinner v-if="loadingQuestions" class="txt-align-center ttl" />
      </div>

      <div class="actions-btn">
        <k-button
          :size="3"
          :label="`${campaign.campaign_id ? 'SAVE' : 'CREATE'} DRAFT`"
          :disabled="campaign.status !== 'draft' || mode === 'VIEW'"
          @click="handleDraft"
        />
        <k-button
          :size="3"
          label="LAUNCH CAMPAIGN"
          :disabled="mode === 'VIEW'"
          @click="launchCampaign()"
        />
        <k-button
          v-if="`${campaign.campaign_id}`"
          :size="3"
          label="DELETE DRAFT"
          :disabled="campaign.status !== 'draft' || mode === 'VIEW'"
          @click="onClickConfirm({ type: 'campaign ' })"
        />
        <b-loading-spinner v-if="launching" class="txt-align-center ttl" />
      </div>
    </div>
    <ConfirmModal
      v-if="showConfirmBoxModal"
      class="modal-mask"
      :type="confirmationObj.type"
      @cancel="showConfirmBoxModal = false"
      @remove="onClickConfirmSubmit"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { isBlank } from 'adready-api/helpers/common';
import { get } from 'vuex-pathify';
import advertiserReportsAPI from '~/api/advertiser-reports';
import { buildQueryString } from '~/helpers/global/url-helpers';
import { formatDateString } from '~/helpers/global/misc-helpers';
import ConfirmModal from '~/pages/ConfirmModal.vue';

export default {
  components: {
    KDatePicker: () =>
      import(
        /* webpackChunkName: "k-date-picker" */ 'adready-vue/components/elements/k-date-picker.vue'
      ),
    SpcGroupingContainer: () =>
      import(/* webpackChunkName: "spc-grouping-container" */ '~/pages/SpcGroupingContainer.vue'),
    KNavTabs: () =>
      import(/* webpackChunkName: "k-nav-tabs" */ '~/components/elements/k-nav-tabs.vue'),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    BRadioSelectCheckbox: () =>
      import(
        /* webpackChunkName: "b-radio-select-checkbox" */ '~/components/elements/b-radio-select-checkbox.vue'
      ),
    ConfirmModal,
  },
  props: {
    initialCampaign: {
      required: true,
      default() {
        return {};
      },
      type: Object,
    },
    advertiser: {
      required: true,
      default() {
        return {};
      },
      type: Object,
    },
    selectedIO: {
      required: true,
      default() {
        return {};
      },
      type: Object,
    },
    mode: {
      type: String,
      required: false,
      default: 'VIEW',
    },
  },
  data() {
    const questions = JSON.parse(
      JSON.stringify(this.initialCampaign.draft_settings.questions || [])
    );
    const baselineQuestions = JSON.parse(
      JSON.stringify(this.initialCampaign.draft_settings.baseline_questions || [])
    );
    return {
      campaign: JSON.parse(JSON.stringify(this.initialCampaign)),
      industries: [],
      cntryLangs: [],
      industriesCntryLangsLoading: true,
      launching: false,
      questions: questions || [],
      baseline_questions: baselineQuestions || [],
      expectedimpressionscount: this.initialCampaign.draft_settings.expected_impressions_count,
      loadingQuestions: false,
      activeTab: 'SURVEY',
      errorMsg: null,
      errorBrandCompetitorMsg: null,
      showConfirmBoxModal: false,
      confirmationObj: null,
      saveAndLaunch: false,
      menuItems: [
        {
          name: 'Survey',
          to: '',
        },
      ],
      appliedGlobalParams: {
        inTargetBrand: this.advertiser ? this.advertiser.name : '',
        competitiveBrand1: '',
        competitiveBrand2: '',
        competitiveBrand3: '',
        competitiveBrand4: '',
      },
      stagedGlobalParams: {
        inTargetBrand: this.advertiser ? this.advertiser.name : '',
        competitiveBrand1: '',
        competitiveBrand2: '',
        competitiveBrand3: '',
        competitiveBrand4: '',
      },
    };
  },
  computed: {
    account: get('common/account'),
    isApplyGlobalParamsBtnDisabled() {
      return !Object.values(this.stagedGlobalParams).every((v) => v.trim().length);
    },
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      return null;
    },
    errorBrandCompetitor() {
      if (!isBlank(this.errorBrandCompetitorMsg)) {
        return this.errorBrandCompetitorMsg;
      }
      return null;
    },
  },
  async mounted() {
    try {
      await this.prepQuestionsOptionsSection();
    } catch (err) {
      console.error('error mounting campaign component ->', err);
    }
  },
  methods: {
    isBlankCampaign() {
      if (isBlank(this.campaign.campaign_id)) {
        return true;
      }
      return false;
    },
    updateCustomQuestionText(index) {
      this.questions[index].custom_question_text = this.questions[index].question_text;
      this.questions[index].questionParams.forEach((qp) => {
        let val = qp.value;
        if (qp.param === 'online') {
          val = 'online';
        }
        this.questions[index].custom_question_text = this.questions[
          index
        ].custom_question_text.replace(`{{${qp.param}}}`, val || `{{${qp.param}}}`);
      });
    },
    applyGlobalParams() {
      this.appliedGlobalParams = JSON.parse(JSON.stringify(this.stagedGlobalParams));
    },
    clearGlobalParams() {
      this.stagedGlobalParams = {
        inTargetBrand: this.advertiser ? this.advertiser.name : '',
        competitiveBrand1: '',
        competitiveBrand2: '',
        competitiveBrand3: '',
        competitiveBrand4: '',
      };
      this.applyGlobalParams();
    },
    onCampaignEndDateChange(date) {
      date.end._d.setUTCHours(23, 59, 59);
      this.campaign.end_date = date.end._d.toISOString();
    },
    onCampaignStartDateChange(date) {
      date.start._d.setUTCHours(0, 0, 0, 0);
      this.campaign.start_date = date.start._d.toISOString();
    },
    getPlaceHolders(questions) {
      const arrayPlaceHolder = [];
      questions.forEach((q) => {
        if (q.question_text.includes('{{')) {
          const part = q.question_text.split('{{');
          part.forEach((i) => {
            if (i.includes('}}')) {
              const eachPart = `{{${i.substring(0, i.lastIndexOf('}') + 1)}`;
              arrayPlaceHolder.push(eachPart);
            }
          });
        }
      });
      return arrayPlaceHolder;
    },
    validatePlaceHolder() {
      const questions = JSON.parse(JSON.stringify(this.questions));
      const placeholders = this.getPlaceHolders(questions);
      let flagToContinue = true;
      this.errorBrandCompetitorMsg = null;
      let index = 0;
      questions.every((q) => {
        placeholders.every((p) => {
          if (
            q.question_text.includes(p) &&
            (q.custom_question_text.includes(p) || isBlank(q.custom_question_text))
          ) {
            this.errorBrandCompetitorMsg = `Please enter the place holder for ${p}`;
            this.$refs.customQuestion[index].focus();
            this.$refs.customQuestion[index].classList.add('errorBox');
            flagToContinue = false;
            return flagToContinue;
          }

          if (q.question_text.includes(p)) {
            index++;
          }
          return flagToContinue;
        });
        return flagToContinue;
      });
      return flagToContinue;
    },
    validateCompititiveBrands() {
      const questions = JSON.parse(JSON.stringify(this.questions));
      const brands = [
        'Competitive Brand 1',
        'Competitive Brand 2',
        'Competitive Brand 3',
        'Competitive Brand 4',
        'Competitive Brand #1',
        'Competitive Brand #2',
        'Competitive Brand #3',
        'Competitive Brand #4',
      ];
      let flagToContinue = true;
      this.errorBrandCompetitorMsg = null;
      let index = 0;
      questions.every((q) => {
        q.answers = q.answers.map((m) => m.text);
        q.answers.every((v) => {
          if (brands.includes(v)) {
            this.errorBrandCompetitorMsg = `Please enter correct ${v}`;
            this.$refs.answer[index].focus();
            this.$refs.answer[index].classList.add('errorBox');
            flagToContinue = false;
            return flagToContinue;
          }
          index++;
          return flagToContinue;
        });

        return flagToContinue;
      });
      return flagToContinue;
    },

    validateData() {
      if (isBlank(this.campaign.campaign_name)) {
        this.errorMsg = 'Please enter campaign name';
        this.$refs.campaign_nameInput.focus();
        this.$refs.campaign_nameInput.classList.add('errorBox');
        return false;
      }

      const currentDate = moment(formatDateString(''), 'YYYY-MM-DD');
      const startDate = this.campaign.start_date;
      if (moment(startDate).isSameOrBefore(currentDate)) {
        this.errorMsg = 'Start date must be current or future date';
        return false;
      }

      const endDate = this.campaign.end_date;
      if (moment(endDate).isSameOrBefore(moment(startDate).add(1, 'days'))) {
        this.errorMsg = 'End date must be after Start date';
        return false;
      }
      if (
        isBlank(this.expectedimpressionscount) ||
        this.expectedimpressionscount < 1000000 ||
        this.expectedimpressionscount > 2147483647 ||
        this.expectedimpressionscount.toString().indexOf('.') !== -1
      ) {
        this.errorMsg =
          'Please enter a valid integer from 1M to 2147483647 for Expected Impressions Count ';
        this.$refs.impression_cnt.focus();
        this.$refs.impression_cnt.classList.add('errorBox');
        return false;
      }

      this.errorMsg = null;
      return true;
    },

    onSelectIndustries(payload) {
      this.industries = JSON.parse(JSON.stringify(payload));

      this.campaign.industry_id = parseInt(this.industries.find((ind) => ind.selected).id, 10);
      this.clearGlobalParams();
      this.fetchQuestions();
    },

    async updateActiveMenu(tab = '') {
      tab = tab.toUpperCase();
      if (this.activeTab.toUpperCase() !== tab) {
        this.activeTab = tab;

        if (this.activeTab.toUpperCase() !== 'GLOBAL PARAMS') {
          await this.fetchQuestions();
        }
      }
    },
    async fetchBrandLiftIndustries() {
      try {
        const data = await advertiserReportsAPI.fetchBrandLiftIndustries(
          this.advertiser ? this.advertiser.id : 0
        );
        data.forEach((item, i) => {
          item.event = item.industry_name;
          item.category = item.id;
          item.name = item.industry_name;

          if (this.campaign.industry_id === null && i === 0) {
            data[0].selected = true;
            this.campaign.industry_id = parseInt(item.id, 10);
          } else {
            item.selected = this.campaign.industry_id === parseInt(item.id, 10);
          }
        });

        this.industries = data || [];
      } catch (err) {
        console.error('error fetching brandlift campaigns', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    applyQuestionParams(item) {
      item.questionParams = [];
      // eslint-disable-next-line
      const reg = new RegExp('[^{\}]+(?=})', 'gm');
      let match = reg.exec(item.question_text);
      while (match !== null) {
        item.questionParams.push({
          param: match[0],
          start: match.index,
          end: match.index + match[0].length,
          value: '',
        });
        match = reg.exec(item.question_text);
      }
    },
    async fetchTemplateQuestions(usageValue) {
      const data = await advertiserReportsAPI.fetchBrandLiftTemplateQuestions(
        this.advertiser ? this.advertiser.id : 0,
        buildQueryString({
          countryLanguageId: this.campaign.country_language_id || '',
          industryId: this.campaign.industry_id || '',
          usage: usageValue,
        })
      );

      const globalParamNames = Object.keys(this.appliedGlobalParams);
      data.forEach((item) => {
        item.answers = item.answers || [];
        item.answers = item.answers.map((answer, i) => {
          answer = answer || '';
          if (
            usageValue === 'lift' &&
            item.kpi_type !== 'brand_favorability' &&
            i < globalParamNames.length
          ) {
            return { text: this.appliedGlobalParams[globalParamNames[i]] || answer };
          }
          if (
            usageValue === 'lift' &&
            item.kpi_type === 'brand_favorability' &&
            item.question_type === 'table-radio' &&
            i < globalParamNames.length
          ) {
            return { text: this.appliedGlobalParams[globalParamNames[i]] || answer };
          }
          return { text: answer };
        });
        this.applyQuestionParams(item);
      });
      return data;
    },
    applyGlobalParamsToLiftQuestions() {
      const globalParamNames = Object.keys(this.appliedGlobalParams);
      this.questions = this.initialCampaign.draft_settings.questions;
      this.questions.forEach((item) => {
        item.answers = item.answers || [];
        item.answers = item.answers.map((answer, i) => {
          if (item.kpi_type !== 'brand_favorability' && i < globalParamNames.length) {
            return { text: this.appliedGlobalParams[globalParamNames[i]] || answer.text };
          }
          if (
            item.kpi_type === 'brand_favorability' &&
            item.question_type === 'table-radio' &&
            i < globalParamNames.length
          ) {
            return { text: this.appliedGlobalParams[globalParamNames[i]] || answer.text };
          }
          return { text: answer.text };
        });

        this.applyQuestionParams(item);
      });
    },
    applyGlobalParamsToBaselineQuestions() {
      this.baseline_questions = this.initialCampaign.draft_settings.baseline_questions;
      this.baseline_questions.forEach((item) => {
        item.answers = item.answers || [];
        item.answers = item.answers.map((answer) => {
          return { text: answer.text };
        });

        this.applyQuestionParams(item);
      });
    },
    async fetchQuestions() {
      this.loadingQuestions = true;
      this.errorBrandCompetitorMsg = null;
      const isIndustryChanged = this.initialCampaign.industry_id !== this.campaign.industry_id;
      const isCountryLanguageChanged =
        this.initialCampaign.country_language_id !== this.campaign.country_language_id;

      if (!isIndustryChanged && !isCountryLanguageChanged) {
        if (
          this.activeTab.toUpperCase() === 'SURVEY' &&
          this.initialCampaign.draft_settings.questions.length
        ) {
          this.applyGlobalParamsToLiftQuestions();
          setTimeout(() => {
            this.loadingQuestions = false;
          }, 500);
          return;
        }
        if (this.initialCampaign.draft_settings.baseline_questions.length) {
          this.applyGlobalParamsToBaselineQuestions();
          setTimeout(() => {
            this.loadingQuestions = false;
          }, 500);
          return;
        }
      }

      try {
        const liftData = await this.fetchTemplateQuestions('lift');
        const baselineData = await this.fetchTemplateQuestions('baseline');
        this.questions = liftData || [];
        this.baseline_questions = baselineData || [];
      } catch (err) {
        console.error('error fetching brandlift campaigns', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.loadingQuestions = false;
      }
    },
    async fetchBrandLiftCntryLang() {
      try {
        const data = await advertiserReportsAPI.fetchBrandLiftCntryLang(
          this.advertiser ? this.advertiser.id : 0
        );
        data.forEach((item, i) => {
          item.event = `${item.country_name} - ${item.language_name}`;
          item.category = item.id;
          item.name = `${item.country_name} - ${item.language_name}`;

          if (this.campaign.country_language_id === null && i === 0) {
            data[0].selected = true;
            this.campaign.country_language_id = 1;
          } else {
            item.selected = this.campaign.country_language_id === parseInt(item.id, 10);
          }
        });
        this.cntryLangs = data || [];
      } catch (err) {
        console.error('error fetching brandlift campaigns', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    async prepQuestionsOptionsSection() {
      try {
        this.industriesCntryLangsLoading = true;

        await Promise.allSettled([this.fetchBrandLiftCntryLang(), this.fetchBrandLiftIndustries()]);
        if (!this.questions.length) {
          await this.fetchQuestions();
        }
        this.clearGlobalParams();
      } catch (err) {
        console.error('error prepping brandlift questions section', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.industriesCntryLangsLoading = false;
      }
    },
    async editCampaignDraft() {
      const payload = JSON.parse(JSON.stringify(this.campaign));
      const questions = JSON.parse(JSON.stringify(this.questions));
      questions.forEach((q) => {
        q.answers = q.answers.map((m) => m.text);
        q.questionParams = q.questionParams.map((m) => m.param);
        q.custom_question_text = q.custom_question_text || '';
        q.is_custom_question_text = q.custom_question_text.trim().length > 0;
        q.target_option_index = 0;
        if (q.multi_target_option_index.length > 0) {
          q.multi_target_option_index[0] = 0;
        }
      });
      payload.draft_settings.questions = questions;
      payload.draft_settings.expected_impressions_count = this.expectedimpressionscount;
      const baselineQuestions = JSON.parse(JSON.stringify(this.baseline_questions));
      baselineQuestions.forEach((q) => {
        q.answers = q.answers.map((m) => m.text);
        q.questionParams = q.questionParams.map((m) => m.param);
        q.custom_question_text = q.custom_question_text || '';
        q.is_custom_question_text = q.custom_question_text.trim().length > 0;
      });
      payload.draft_settings.baseline_questions = baselineQuestions;
      try {
        await advertiserReportsAPI.editBrandLiftCampaign(
          [this.advertiser ? this.advertiser.id : 0, this.campaign.campaign_id],
          payload
        );
      } catch (err) {
        this.errorMsg = 'Error editing brandlift campaign';
        console.error('error editing brandlift campaign', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        if (this.saveAndLaunch === false) {
          this.$emit('update', JSON.parse(JSON.stringify(this.campaign)));
        }
      }
    },
    async createCampaignDraft() {
      try {
        const payload = JSON.parse(JSON.stringify(this.campaign));
        const questions = JSON.parse(JSON.stringify(this.questions));
        questions.forEach((q) => {
          q.answers = q.answers.map((m) => m.text);
          q.questionParams = q.questionParams.map((m) => m.param);
          q.custom_question_text = q.custom_question_text || '';
          q.is_custom_question_text = q.custom_question_text.trim().length > 0;
          q.target_option_index = 0;
          if (q.multi_target_option_index.length > 0) {
            q.multi_target_option_index[0] = 0;
          }
        });
        payload.draft_settings.questions = questions;
        payload.draft_settings.expected_impressions_count = this.expectedimpressionscount;
        const baselineQuestions = JSON.parse(JSON.stringify(this.baseline_questions));
        baselineQuestions.forEach((q) => {
          q.answers = q.answers.map((m) => m.text);
          q.questionParams = q.questionParams.map((m) => m.param);
          q.custom_question_text = q.custom_question_text || '';
          q.is_custom_question_text = q.custom_question_text.trim().length > 0;
        });
        payload.draft_settings.baseline_questions = baselineQuestions;
        await advertiserReportsAPI.createBrandLiftCampaign(
          [
            this.advertiser ? this.advertiser.id : 0,
            this.account ? encodeURIComponent(this.account.name) : '',
            this.advertiser ? encodeURIComponent(this.advertiser.name) : '',
            this.selectedIO ? encodeURIComponent(this.selectedIO.id) : '',
            this.selectedIO ? encodeURIComponent(this.selectedIO.name) : '',
          ],
          payload
        );
      } catch (err) {
        this.errorMsg = 'Error creating brandlift campaign draft';
        console.error('error creating brandlift campaign draft', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        if (this.saveAndLaunch === false) {
          this.$emit('update', JSON.parse(JSON.stringify(this.campaign)));
        }
      }
    },
    async fetchBrandLiftCampaigns() {
      this.errorBrandCompetitorMsg = null;
      const payload = {
        client: this.account ? this.account.name : '',
        advertiser: this.advertiser ? this.advertiser.name : '',
        io: this.selectedIO ? this.selectedIO.name : '',
      };

      const data = await advertiserReportsAPI.fetchBrandLiftCampaigns(
        this.advertiser ? this.advertiser.id : 0,
        buildQueryString(payload)
      );
      if (data.length > 0) {
        this.campaign = data[data.length - 1] || [];
      }
      if (data.length === 0) {
        this.campaign = [];
      }
    },
    onClickConfirm(item) {
      this.errorBrandCompetitorMsg = null;
      this.confirmationObj = item;
      this.showConfirmBoxModal = true;
    },
    onClickConfirmSubmit() {
      this.deleteDraft();
      this.showConfirmBoxModal = false;
    },
    async deleteDraft() {
      this.errorBrandCompetitorMsg = null;
      try {
        await advertiserReportsAPI.deleteBrandLiftCampaign([
          this.advertiser ? this.advertiser.id : 0,
          this.campaign.campaign_id,
        ]);
      } catch (err) {
        this.errorMsg = 'Error deleting brandlift campaign';
        console.error('error deleting brandlift campaign', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.$emit('update', JSON.parse(JSON.stringify(this.campaign)));
      }
    },
    async handleDraft() {
      const dataValidated = this.validateData();
      if (!dataValidated) {
        return;
      }

      this.campaign.client_name =
        this.account && this.advertiser ? `${this.account.name}-${this.advertiser.name}` : '';
      if (this.campaign.campaign_id) {
        await this.editCampaignDraft();
      } else {
        await this.createCampaignDraft();
      }
    },
    async launchCampaign() {
      this.launching = true;

      const validCampaign = this.validateData();

      if (!validCampaign) {
        this.launching = false;
        return;
      }

      const validAnswer = this.validateCompititiveBrands();
      if (!validAnswer) {
        this.launching = false;
        return;
      }

      const validPlaceholder = this.validatePlaceHolder();
      if (!validPlaceholder) {
        this.launching = false;
        return;
      }

      try {
        this.saveAndLaunch = true;
        this.campaign.client_name =
          this.account && this.advertiser ? `${this.account.name}-${this.advertiser.name}` : '';
        if (this.campaign.campaign_id) {
          await this.editCampaignDraft();
        } else {
          await this.createCampaignDraft();
          await this.fetchBrandLiftCampaigns();
        }
        await advertiserReportsAPI.launchBrandLiftCampaign([
          this.advertiser ? this.advertiser.id : 0,
          this.campaign.campaign_id,
        ]);
      } catch (err) {
        this.saveAndLaunch = false;
        console.error('Error launching brandlift campaign', err);
        this.errorMsg = 'Error launching brandlift campaign';
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.saveAndLaunch = false;

        if (isBlank(this.errorMsg)) {
          this.$emit('update', JSON.parse(JSON.stringify(this.campaign)));
        }
        this.launching = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.control-wrap {
  display: flex !important;
  align-items: center;
}
.question,
.answers {
  display: flex;
  width: 100%;
  .ttl {
    width: 80%;
  }
  & > *:first-child {
    // flex: 0 1 20%;
    width: 18%;
    color: #97a7bb;
  }

  &:not(:first-child) {
    margin-top: 1rem;
  }
}
.lbl-txt {
  color: #97a7bb;
}
.qna {
  height: 50rem;
  overflow: scroll;
}

.ans-select {
  width: 780px;
  padding: 1rem;
  margin-top: 15px;
  color: #cad1d6;
  background-color: #323239;
  border-radius: 4px;
  &:first-child {
    margin-top: 5px;
  }
  &:hover {
    border: 1px solid var(--adminprimarycolor);
  }
  &:disabled {
    &:hover {
      border: none;
    }
  }
}

.nav-tabs {
  position: absolute;
  top: 0;
  left: 16px;
  li {
    min-width: 70px;
    text-align: center;
    span {
      font-size: 13px;
      font-weight: 400;
    }
  }
}
::v-deep .nav-tabs .tab-active a {
  top: 53px !important;
}

table {
  width: 100%;
  padding: 1rem;
  font-size: 0.875rem;
  color: #cad1d6;
  tr {
    line-height: 50px;
    border-bottom: 1px solid #404040;
    &:first-child {
      border-bottom: 1px solid #858585;
    }
    td {
      & > svg {
        margin-left: 1rem;
      }
    }
  }
  tr th,
  td {
    padding: 0.5rem 1rem;
    &:last-child {
      text-align: end;
    }
  }
}

.sec {
  position: relative;
  display: block;
  padding: 1rem;
  margin-top: 1rem;
  background-color: #212429;
  border-radius: 4px;
  svg {
    cursor: pointer;
  }
}

.ttl {
  display: inline-block;
  font-size: 0.875rem;
  color: #cad1d6;
  letter-spacing: 0.07em;
}

.txt-align-center {
  width: 100%;
  font-size: 13px;
  color: #bac0c5;
  text-align: center;
}
.container > * {
  display: inherit;
  width: 1100px;
  margin: 0 auto;
}

.campaign-form {
  display: block;

  .form-inp {
    &:first-child {
      display: block;
    }
  }
}

.form-inp {
  display: inline-block;
  padding: 1rem;

  label {
    width: 122px;
    margin-right: 2rem;
  }
  .camp-name {
    width: 850px;
  }
}

.form-date-inp {
  display: flex;
  padding: 1rem;
  align-items: center;
  label {
    width: 92px;
    margin-right: 4rem;
    cursor: default;
  }
  .last-lbl {
    margin-left: 2.9rem;
    margin-right: 3rem;
  }
  .date-box {
    width: 338px;
    color: #cad1d6;
  }
}
::v-deep .form-input.form-date .form-input-field {
  border-radius: 4px;
}
input,
select {
  padding: 15px;
  color: #cad1d6;
  background-color: #323239;
  border: none;
  border-radius: 4px;
  &::placeholder {
    color: #cad1d6;
  }
}

select {
  padding: 12px;
}
.lookup {
  padding: 25px;
  label {
    width: 130px;
  }
  select {
    width: 315px;
  }
}
.no-bg {
  background: transparent !important;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  margin-right: 20px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--adminprimarycolor);
  border-radius: 3px;
  appearance: none;
  &:disabled {
    background: #7c7c7c !important;
    color: #d0d0d0 !important;
    pointer-events: none;
  }
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 22px;
  line-height: 20px;
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
::v-deep .wide-box {
  min-width: 850px !important;
  margin-right: 0 !important;
}
.actions-btn {
  margin: 40px 5px;
}
.main-title {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}
.camplist-box {
  min-height: 150px;
  align-items: center;
  display: flex;
}
.qa-box {
  margin-top: 25px;
}
.param-box {
  border: 1px solid hsla(0, 0%, 66.7%, 0.1);
}

button:disabled {
  /* background: red; */
  color: #d0d0d0 !important;
  border-top: 1px solid #ececec !important;
  border-right: 1px solid #ececec !important;
  border-bottom: 1px solid #ececec !important;
  border-left: 1px solid #ececec !important;
}
::v-deep .dropdown.disabled {
  opacity: 1;
  .select {
    svg {
      display: none;
    }
  }
}
.custom-ques-blk {
  display: flex;
  width: 96% !important;
  .ttl {
    color: #97a7bb;
    text-transform: capitalize;
    &:last-child {
      text-transform: none;
      width: 100%;
    }
  }
  /*
  .ans-select {
    border: 1px solid #e7e7e7;
    background-color: #1e1e20;
  }
  */
}
::v-deep input::placeholder {
  color: #6c6c6c !important;
}
::v-deep .selected-opt-wrapper .selected-opt-header {
  display: none;
}
.required {
  color: #fc4242;
}
.error {
  padding: 10px;
  font-size: 13px;
  color: rgba(252, 66, 66);
  letter-spacing: 0.9px;
}
</style>
